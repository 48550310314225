import React, {useCallback, useState, useEffect } from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import './assets/styles/App.css'
import Audio from './pages/audio';
import Empty from './pages/empty';
import Streams from './pages/admin-pages/streams';
import PlanStream from './pages/admin-pages/plan-stream';
import FlyoutMenu from './components/flyout';
import {  
    PWordmark,
    PButtonPure,} from '@porsche-design-system/components-react';

import { SocketProvider} from './websocket/websocket';
import { EventProvider} from './websocket/eventContext';

export default function App() {
    const [isFlyoutNavigationOpen, setIsFlyoutNavigationOpen] = useState(false);
    const [flyoutNavigationActiveIdentifier, setFlyoutNavigationActiveIdentifier] = useState('audio');

    const onOpen = useCallback(() => {
        setIsFlyoutNavigationOpen(true);
    }, []);
    const onDismiss = useCallback(() => {
        setIsFlyoutNavigationOpen(false);
    }, []);
    const onUpdate = useCallback(
        (e) =>
        setFlyoutNavigationActiveIdentifier(e.detail.activeIdentifier),
        []
    );

    const handleLogout = () => {
        //link to logout page
    }


    return (
        <EventProvider>
            <SocketProvider>
                <div className="grid-container">
                
                    <PWordmark className='wordmark'theme='dark'/>

                    <PButtonPure className="menu" 
                        icon='menu-lines' 
                        theme="dark" 
                        type="button" 
                        aria={{ 'aria-haspopup': 'dialog' }} 
                        onClick={() => onOpen()}>
                        Menu
                    </PButtonPure>

                    <PButtonPure className='user' icon='user' theme='dark'/>

                    <FlyoutMenu
                        isFlyoutNavigationOpen={isFlyoutNavigationOpen}
                        onDismiss={onDismiss}
                        onUpdate={onUpdate}
                        flyoutNavigationActiveIdentifier={flyoutNavigationActiveIdentifier}
                    />
                    
                    <Routes>
                        <Route path="/" element={<Empty/>} />
                        <Route path="/audio" element={<Audio />} />
                        <Route path='/streams' element={<Streams />} />
                        <Route path='/plan-stream' element={<PlanStream />} />
                    </Routes>
                
                </div>
            </SocketProvider>
        </EventProvider>
    );

}