import React, { useState, useCallback, useEffect } from 'react';
import '../../assets/styles/plan-stream.css'
import { useSocket } from '../../websocket/websocket';


// Helper function to convert Unix timestamp to date and time strings
function convertTimestamp(timestamp) {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return {
      date: `${year}-${month}-${day}`,
      time: `${hours}:${minutes}`
    };
}

const convertToUnixTimestamp = (date, time) => {
    return Math.floor(new Date(`${date}T${time}`).getTime() / 1000);
};

export default function PlanStream(){
    const {streamPlan, eventList, socket} = useSocket();

    const [selectedSession, setSelectedSession] = useState('');
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    const [plannedStreams, setPlannedStreams] = useState([]);
    const [selectedStreams, setSelectedStreams] = useState([]);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('');
    const [sessions, setSessions] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [selectedDrivers, setSelectedDrivers] = useState([]);

    useEffect(() => {
        socket.on('on_streamplan_updated', (message) => {
            console.log(message)
            if(message.success === true){
                setPlannedStreams((prevPlannedStreams) =>
                    prevPlannedStreams.filter((stream) => !selectedStreams.includes(stream.id))
                );
            }
        })
    }, [])

    useEffect(() => {
        if(eventList){
            setEvents(eventList)
            console.log(eventList)
        }
    }, [eventList])

    // get streamplan and transform
    useEffect(()=>{
        if(streamPlan){
            const transformedStreamPlan = streamPlan.data.map((item) => {
                const start = convertTimestamp(item.start);
                const end = convertTimestamp(item.end);
                return{
                    id: item.id,
                    sessionId: item.session.sessionId,
                    session: item.session.sessionName,
                    startDate: start.date,
                    startTime: start.time,
                    endDate: end.date,
                    endTime: end.time,
                    eventId: item.event.eventId,
                    drivers: item.drivers
                }
            })
            setPlannedStreams(transformedStreamPlan)
        }
    }, [streamPlan])
    
    // for session ids
    useEffect(() => {
        if (selectedEvent){
            console.log(selectedEvent)
        }
        
        // request session ids for event
        socket.emit('MR', {
            operation: 'MR_SUBSCRIBE_EVENT',
            params: {eventId: selectedEvent}
        })

        // get session ids for event
        socket.on('MR_EVENT_UPDATED', (message) => {
            const sessions = Object.keys(message.newIndex.sessions).map((sessionId) => {
                const session = message.newIndex.sessions[sessionId];
                return {
                  sessionId: session.docID,
                  sessionName: session.name
                };
              });
            setSessions(sessions)
        })
    }, [selectedEvent, socket]);

    // for stream names (driver)
    useEffect(() => {
        socket.emit('_driverRadio', {
            event: 'on_get_drivers',
            operation: 'getDrivers'
        })

        socket.on('on_get_drivers', (message) => {
            console.log(message)
            const streams = message.data.map((driver, index) => ({
                index,
                streamId: driver.driverId,
                streamName: driver.driverNameShort
            }))
            setDrivers(streams)
        })
    }, [socket]);

    // for updated streamplan
    useEffect(() => {
        socket.on('on_streamplan_updated', (message) => {
            console.log(message)
            if (message.success){
                const newStreamPlans = message.params.map((item) => {
                    const start = convertTimestamp(item.start);
                    const end = convertTimestamp(item.end);
                    return {
                      id: item.id,
                      sessionId: item.session.sessionId,
                      session: item.session.sessionName,
                      startDate: start.date,
                      startTime: start.time,
                      endDate: end.date,
                      endTime: end.time,
                      eventId: item.event.eventId,
                      drivers: item.drivers
                    };
                  });
                  setPlannedStreams((prevPlannedStreams) => [...prevPlannedStreams, ...newStreamPlans]);
            }
        })
    },[])

    // add a stream
    const handleAddStream = () => {
        const startTimestamp = convertToUnixTimestamp(startDate, startTime);
        const endTimestamp = convertToUnixTimestamp(endDate, endTime);

        socket.emit('_driverRadioStreams', {
            event: 'on_streamplan_updated',
            operation: 'addStreams',
            params:[{
                event: {
                    eventId: selectedEvent,
                    eventName: events.find(e => e.docID === selectedEvent)?.name,
                },
                session: {
                    sessionId: selectedSession,
                    sessionName: sessions.find(s => s.sessionId === selectedSession)?.sessionName
                },
                drivers: selectedDrivers,
                start: startTimestamp,
                end: endTimestamp
            }]
        })

        // Reset fields
        setSelectedSession('');
        setStartDate('');
        setStartTime('');
        setEndDate('');
        setEndTime('');
        setSelectedDrivers([]);
    };
    
    // handler for selecting a stream
    const handleSelectStream = (id) => {
        setSelectedStreams((prevSelectedStreams) =>
            prevSelectedStreams.includes(id)
                ? prevSelectedStreams.filter((streamId) => streamId !== id)
                : [...prevSelectedStreams, id]
        );
    };

    //  delete stream(s)
    const handleDeleteStreams = () => {
        console.log(selectedStreams)
        
        socket.emit('_driverRadioStreams', {
            event: 'on_streamplan_updated',
            operation: 'removeStreams',
            params: selectedStreams
        })

        setPlannedStreams((prevPlannedStreams) =>
            prevPlannedStreams.filter((stream) => !selectedStreams.includes(stream.id))
        );
        setSelectedStreams([]);

        socket.on('on_streamplan_updated', (message) => {
            console.log(message)
            if(message.success === true){
                setPlannedStreams((prevPlannedStreams) =>
                    prevPlannedStreams.filter((stream) => !selectedStreams.includes(stream.id))
                );
                setSelectedStreams([]);
            }
        })
    };

    // handler for selected drivers
    const handleDriverChange = (e) => {
        const options = e.target.options;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            selected.push(options[i].value);
          }
        }
        setSelectedDrivers(selected);
    };

    // helper to fit drivers in column
    const renderDrivers = (drivers) => {
        const maxDisplay = 3;
        const displayedDrivers = drivers.slice(0, maxDisplay).join(', ');
        const remainingDrivers = drivers.length - maxDisplay;
        
        return (
            <span title={drivers.join(', ')}>
                {displayedDrivers}
                {remainingDrivers > 0 && (
                    <span> and {remainingDrivers} more</span>
                )}
            </span>
        );
    };
    
    const filteredPlannedStreams = plannedStreams.filter(stream => stream.eventId === selectedEvent);
    
    return(
        <div className="content">
            <div className="stream-planner">
                <div>
                    <select
                        id="event"
                        value={selectedEvent}
                        onChange={(e) => setSelectedEvent(e.target.value)}
                    >
                        <option value="">Select an event</option>
                        {events.map((event) => (
                        <option key={event.docID} value={event.docID}>
                            {event.name}
                        </option>
                        ))}
                    </select>
                </div>
                <div className="planner-form">
                    <h3>Plan a Stream</h3>
                    <div className="form-group">
                        <label htmlFor="session">Session:</label>
                        <select
                            id="session"
                            value={selectedSession}
                            onChange={(e) => setSelectedSession(e.target.value)}
                        >
                            <option value="">Select a session</option>
                            {sessions.map((session) => (
                                <option key={session.sessionId} value={session.sessionId}>
                                    {session.sessionName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="drivers">Drivers:</label>
                        <select
                            id="drivers"
                            multiple
                            value={selectedDrivers}
                            onChange={handleDriverChange}
                        >
                            {drivers.map((driver) => (
                                <option key={driver.index} value={driver.driver}>
                                    {driver.streamName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="start-date">Start Date:</label>
                        <input
                            type="date"
                            id="start-date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="start-time">Start Time:</label>
                        <input
                            type="time"
                            id="start-time"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="end-date">End Date:</label>
                        <input
                            type="date"
                            id="end-date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="end-time">End Time:</label>
                        <input
                            type="time"
                            id="end-time"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                        />
                    </div>
                    <button onClick={handleAddStream}>Add Stream</button>
                </div>
                <div className="planned-streams">
                    <h3>Planned Streams</h3>
                    <table className='styled-table'>
                        <thead>
                            <tr>
                                <th>Session</th>
                                <th>Streams</th>
                                <th>Start Date</th>
                                <th>Start Time</th>
                                <th>End Date</th>
                                <th>End Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPlannedStreams.map((stream) => (
                                <tr key={stream.id}
                                    className={selectedStreams.includes(stream.id) ? 'selected-row' : ''}
                                    onClick={() => handleSelectStream(stream.id)}
                                >
                                    <td>{stream.session}</td>
                                    <td>{renderDrivers(stream.drivers)}</td>
                                    <td>{stream.startDate}</td>
                                    <td>{stream.startTime}</td>
                                    <td>{stream.endDate}</td>
                                    <td>{stream.endTime}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button onClick={handleDeleteStreams} className="delete-button2">
                        Delete Selected Streams
                    </button>
                </div>
            </div>
                     
        </div>
    )
}