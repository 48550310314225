import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { PorscheDesignSystemProvider } from '@porsche-design-system/components-react';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <PorscheDesignSystemProvider>
        <App />
      </PorscheDesignSystemProvider> 
    </BrowserRouter> 
  // </React.StrictMode>
)