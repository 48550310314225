import React, {useCallback, useState, useEffect } from 'react';
import { useSocket } from '../websocket/websocket';
import Card from '../components/card'
import '../assets/styles/audio.css'
import '../assets/styles/custom.css'
import { useEvent } from '../websocket/eventContext';


export default function Audio(){
    const {socket, eventList, streamUrls} = useSocket();
    
    const { selectedEventId } = useEvent();
    const [events, setEvents] = useState([]);
    const [streams, setStreams] = useState([]);
    const [filteredStreams, setFilteredStreams] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [streamurls, setStreamUrls] = useState([]);
    const [selectedSessionId, setSelectedSessionId] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const [transcriptsShort, setTranscriptsShort] = useState(null)
    const [streamStatuses, setStreamStatuses] = useState(new Set());

    useEffect(() => {
        // get session ids for event
        socket.on('MR_EVENT_UPDATED', (message) => {
            console.log('MR events',message)
            const sessions = Object.keys(message.newIndex.sessions).map((sessionId) => {
                const session = message.newIndex.sessions[sessionId];
                return {
                    sessionId: session.docID,
                    sessionName: session.name
                };
                });
            setSessions(sessions)
        })

        // get all transcripts for selected event and session
        socket.on('on_get_all_transcripts', (message) => {
            console.log('transcripts: ', message)
            const transformedTranscripts = Object.keys(message.data).reduce((acc, driverId) => {
                acc[driverId] = message.data[driverId].transcription;
                return acc;
              }, {});
              setTranscriptsShort(transformedTranscripts);
        })

        // response when there is a new transcription
        socket.on('on_get_new_transcription', (message) => {
            console.log('new transcription', message);
            setTranscriptsShort((prevTranscriptsShort) => {
                if (prevTranscriptsShort[message.driver_id]) {
                    return {
                        ...prevTranscriptsShort,
                        [message.driver_id]: [
                            ...prevTranscriptsShort[message.driver_id],
                            message.msg
                        ]
                    };
                } else {
                    console.error(`Driver with ID ${message.driver_id} not found.`);
                    return prevTranscriptsShort;
                }
            });
        })

        socket.on('on_get_updated_transcription', (message) => {
            console.log('updated transcription',message)
            
            setTranscriptsShort((prevTranscriptsShort) => {
                if (prevTranscriptsShort[message.driver_id]) {
                    const updatedTranscripts = prevTranscriptsShort[message.driver_id].map((transcription) =>
                        transcription.RecognitionTime === message.msg.RecognitionTime ? message.msg : transcription
                    );
                    return {
                        ...prevTranscriptsShort,
                        [message.driver_id]: updatedTranscripts
                    };
                } else {
                    console.error(`Driver with ID ${message.driver_id} not found.`);
                    return prevTranscriptsShort;
                }
            });
        })

        // get active streams
        socket.on('on_get_streams_streams', (message) => {
            console.log(message)
            const newStreamStatuses = new Set(streamStatuses);

            Object.keys(message).forEach((streamName) => {
                newStreamStatuses.add(streamName);
            });

            setStreamStatuses(newStreamStatuses);
        })

        return () => {
            socket.off('MR_EVENT_UPDATED');
            socket.off('on_get_all_transcripts');
            socket.off('on_get_new_transcription');
            socket.off('on_get_streams_streams');
            socket.off('on_get_updated_transcription')
        };
    }, [])
    
    const handleSelectAll = useCallback(() => {
        setSelectedValues(streams.map(stream => stream.streamName));
    }, [streams]);

    const handleSelect = useCallback((streamName) => {
        setSelectedValues((prevSelectedValues) => {
            if (prevSelectedValues.includes(streamName)) {
                // If already selected, remove it
                return prevSelectedValues.filter((name) => name !== streamName);
            } else {
                // Otherwise, add it
                return [...prevSelectedValues, streamName];
            }
        });
    }, []);

    const handleDeselectAll = useCallback(() => {
        setSelectedValues([]);
    }, []);

    // get event list
    useEffect(() => {
        if (eventList) {
          setEvents(eventList);
        }
      }, [eventList]);

    // for session ids
    useEffect(() => {
        if (selectedEventId){
            console.log(selectedEventId)
        }
        
        // request session ids for event
        socket.emit('MR', {
            operation: 'MR_SUBSCRIBE_EVENT',
            params: {eventId: selectedEventId}
        })

    }, [selectedEventId, socket]);
    
    // map streaUrls to stream array
    useEffect(() => {
        if(streamUrls){
            const streamUrlsData = streamUrls.data
            const streamNameToUrlMap = Object.keys(streamUrlsData).reduce((acc, key) => {
                acc[key] = streamUrlsData[key];
                return acc;
            }, {});
            setStreamUrls(streamNameToUrlMap)
        }
    }, [streamUrls])
   
    // for stream names (driver)
    useEffect(() => {
        socket.emit('_driverRadio', {
            event: 'on_get_drivers',
            operation: 'getDrivers'
        })  
        // get streams(drivers)
        socket.on('on_get_drivers', (message) => {
            console.log('got drivers:',message)
            const streams = message.data.map((driver, index) => ({
                index,
                streamId: driver.driverId,
                streamName: driver.driverNameShort,
                url: streamurls[driver.driverNameShort]
            }))
            setStreams(streams)
        })

        return () => {
            socket.off('on_get_drivers')
        }
    }, [socket, streamurls]);

    // for transcripts and active streams per session
    useEffect(() => {
        if(selectedSessionId){
            console.log('fetching for:', selectedSessionId)
            // transform stream array for request
            const transformedStreams = streams.map(stream => stream.streamName)
            socket.emit('_driverRadio', {
                event: 'on_get_all_transcripts',
                operation: 'getAllTranscripts',
                params: {
                    driver_ids: transformedStreams,
                    event_id: selectedEventId, 
                    session_id: selectedSessionId,
                }
            })

            socket.emit('_driverRadio', {
                event: 'on_get_streams_streams',
                operation: 'getStreams',
                params: {
                    session_id: selectedSessionId
                }
            })
        }
    }, [selectedSessionId, socket])

    useEffect(() => {
        if(streams){
            setFilteredStreams(streams.filter(stream => selectedValues.includes(stream.streamName)))
        }
    }, [selectedValues])

    useEffect(() => {
        if (!selectedSessionId) return;

        if(selectedSessionId){
            socket.emit('_driverRadio', {
                operation: 'startSessionSubs',
                params: {
                    session_id: selectedSessionId
                }
            })
            // Cleanup function to stop the subscription
            return () => {
                socket.emit('_driverRadio', {
                    operation: 'stopSessionSubs',
                    params: {
                        session_id: selectedSessionId
                    }
                });
            };
        }
    }, [selectedSessionId, socket])
    

    const selectedEventName = events.find((event) => event.docID === selectedEventId)?.name;
    

    return(
        <div className="content">
            <div className="btn-group" role='group'>
                {selectedEventName && <h4><b>{selectedEventName},</b></h4>}
                <select
                    id="session"
                    className='form-select form-select-sm' 
                    aria-label="Default select example"
                    value={selectedSessionId}
                    onChange={(e) => setSelectedSessionId(e.target.value)}
                    >
                
                <option >Session</option>
                    {sessions.map((session) => (
                        <option key={session.sessionId} value={session.sessionId}>
                        {session.sessionName}
                        </option>
                    ))}
                </select>
                {selectedSessionId && (
                    <div className='dropdown'>
                        <button className='btn btn-light dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                             Select Streams
                         </button> 
                        <ul className='dropdown-menu'>
                            <li className='dropdown-item'>
                                <button  onClick={handleSelectAll}>
                                    Select All
                                </button>
                                <button onClick={handleDeselectAll}>
                                        X
                                </button>
                            </li>
                                                        
                            <li><hr className="dropdown-divider"/></li>
                            {streams.map((stream) => (
                                <li key={stream.index} className='dropdown-item'>
                                    <input 
                                        type="checkbox"
                                        className='form-check-input'
                                        id={`checkbox-${stream.streamId}`}
                                        checked={selectedValues.includes(stream.streamName)}
                                        onChange={() => handleSelect(stream.streamName)}
                                    />
                                    <label>{stream.streamName}</label>
                                </li>
                            ))} 

                        </ul>
                    </div>
                )}
            </div>
            <div className='container-fluid mt-3'>
                <div className='row'>
                    {filteredStreams.map((stream) => {
                        const isLive = streamStatuses.has(stream.streamName)
                        return (<div className='col-12 col-sm-6 col-xl-4 col-xxl-3 col-xxxl-2 col-xxxxl-1 mb-4' key={stream.index}>
                            <Card
                                title={stream.streamName} 
                                url={stream.url}
                                eventId = {selectedEventId}
                                sessionId = {selectedSessionId}
                                driverId = {stream.streamName}
                                isLive= {isLive}
                                content={(transcriptsShort[stream.streamName] || []).map(transcription => ({
                                    time: transcription.RecognitionTime,
                                    text: transcription.Text,
                                    timestamp: transcription.RecognitionTime,
                                    priority: transcription.priority
                                }))}
                            />
                        </div> 
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}