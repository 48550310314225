import React, { createContext, useState, useContext, useEffect } from 'react';

const EventContext = createContext();

export const useEvent = () => useContext(EventContext);

export const EventProvider = ({ children }) => {
    const [selectedEventId, setSelectedEventId] = useState(() => {
        return localStorage.getItem('selectedEventId') || null;
      });
    
      useEffect(() => {
        if (selectedEventId) {
          localStorage.setItem('selectedEventId', selectedEventId);
        } else {
          localStorage.removeItem('selectedEventId');
        }
      }, [selectedEventId]);
    
  return (
    <EventContext.Provider value={{ selectedEventId, setSelectedEventId }}>
      {children}
    </EventContext.Provider>
  );
};